import "./index.scss";
import Logo from "../../Assets/images/logo.png";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook.svg";
import Whatsapp from "../../Assets/images/icons/whatsapp.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import Whats from "../../Assets/images/icons/whats.svg";
import Fone from "../../Assets/images/icons/fone.svg";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import { numbersOnly } from "../../Utils";

const Footer = () => {
    const { resumo } = useContext(Context);
    const [telefones, setTelefones] = useState([]);
    const [whatsapp, setWhatsapp] = useState([]);

    useEffect(() => {
        let telefones = [];
        let whatsapps = [];
        resumo.telefones.map(telefone => {
            if (telefone.tipo === "telefone") {
                return telefones.push(<a key={`telefone-footer-${telefone.id}`} href={`tel:55${numbersOnly(telefone.telefone)}`} target="_blank" rel="noopener noreferrer"><img alt="Telefone" src={Fone} /> {telefone.telefone} ({telefone.nome})</a>)
            } else {
                return whatsapps.push(<a key={`whatsapp-footer-${telefone.id}`} href={`https://wa.me/55${numbersOnly(telefone.telefone)}`} target="_blank" rel="noopener noreferrer"><img alt="Whatsapp" src={Whats} /> {telefone.telefone} ({telefone.nome})</a>)
            }
        });

        setTelefones(telefones);
        setWhatsapp(whatsapps);
    }, [resumo]);
    return (
        <div id="footer">
            <div className="container">
                <div>
                    <Link to="/">
                        <img alt="Logo" src={Logo} />
                    </Link>
                    <div className="social">
                        <Link to="/">
                            <img alt="Facebook" src={Facebook} />
                        </Link>
                        <Link to="/">
                            <img alt="Whatsapp" src={Whatsapp} />
                        </Link>
                        <Link to="/">
                            <img alt="Instagram" src={Instagram} />
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="col-3">
                        <p> Nosso Site</p>
                        <div>
                            <Link to="/">
                                Home
                            </Link>
                            <Link to="/empresa">
                                A Santana Art's
                            </Link>
                            <Link to="/produtos">
                                Produtos
                            </Link>
                            <Link to="/contato">
                                Contato
                            </Link>
                        </div>
                    </div>
                    <div className="col-3">
                        <p>Produtos</p>
                        <div>
                            {
                                resumo.produtos_linhas && resumo.produtos_linhas.map(
                                    (linha, i) => {
                                        if (i < 4) {
                                            return <Link key={`linha-footer-${linha.id}`} to={`/produtos/linha/${linha.slug}`}>{linha.nome}</Link>
                                        } else {
                                            return false;
                                        }
                                    })
                            }
                            <Link to={`/produtos`}>Ver todos</Link>
                        </div>
                    </div>
                    <div className="col-3">
                        <p>Telefones</p>
                        <div>{telefones}</div>
                    </div>
                    <div className="col-3">
                        <p>WhatsApp</p>
                        <div>{whatsapp}</div>
                    </div>
                </div>
            </div>
            <div className="creditos">
                <div className="container">
                    <p dangerouslySetInnerHTML={{ __html: resumo.configuracoes.enderecos }}></p>
                    <p className="fw2">Desenvolvido por <a href="https://fw2propaganda.com.br" alt="Cássia Viotto" target="_blank" rel="noopener noreferrer">FW2 Propaganda ❤</a></p>
                </div>
            </div>

        </div>
    )
}

export default Footer;