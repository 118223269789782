import "./index.scss";
import { useState } from "react";

import Logo from "../../Assets/images/logo.png";
import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import Carrinho from "../../Assets/images/icons/carrinho.svg";
import Pesquisa from "../../Assets/images/icons/pesquisa.svg";
import { Link, useHistory } from "react-router-dom";

const Header = () => {
    const [menu, setMenu] = useState(false);
    const [pesquisa, setPesquisa] = useState(false);
    const history = useHistory();
    const handlePesquisa = (e) => {
        e.preventDefault();
        let formdata = new FormData(e.target);
        history.push(`/pesquisa/${formdata.get("pesquisa")}`);
        e.target.reset();
        setPesquisa(false);
    }
    return (
        <div id="header">
            <div className="primary">
                <div className="container">
                    <Link to="/">
                        <img alt="Logo" src={Logo} className="logo" />
                    </Link>
                    <button onClick={() => setMenu(!menu)}><img alt="Menu" src={menu ? Close : Menu} /></button>
                    <div className={menu ? "active" : ""}>
                        <Link to="/">
                            Home
                        </Link>
                        <Link to="/empresa">
                            Santana Art's
                        </Link>
                        <Link to="/produtos">
                            Produtos
                        </Link>
                        <Link to="/como-comprar">
                            Como comprar
                        </Link>
                        <button onClick={() => setPesquisa(true)}>
                            <img alt="pesquisa" src={Pesquisa} />
                        </button>
                        <Link to="/carrinho">
                            <img alt="Carrinho" src={Carrinho} />
                        </Link>
                        <Link to="/minha-conta">
                            Minha Conta
                        </Link>
                    </div>
                </div>
            </div>
            <div className={`pesquisa ${pesquisa ? "active" : ""}`}>
                <div className="form">
                    <form onSubmit={handlePesquisa}>
                        <div className="inputs">
                            <label htmlFor="pesquisa">Pesquisar</label>
                            <input type="text" name="pesquisa" id="pesquisa" required />
                            <button type="submit" title="pesquisar"><img src={Pesquisa} alt="pesquisa" /></button>
                            <span onClick={() => setPesquisa(false)}>Voltar ao site</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Header;